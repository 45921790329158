import Vue from 'vue';

export default {
    /* find(parametros) {
        return Vue.axios.get("diccionario-datos/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    get(id) {
        return Vue.axios.get("diccionario-datos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }, */
    update(parametros) {
        return Vue.axios.put("diccionario-datos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("diccionario-datos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    store(parametros) {
        return Vue.axios.post("diccionario-datos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }
}
<template>
    <div id="pageTable">      
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Diccionario'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="false"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template v-if="rol == 'root'" slot="actionButtonsLeft">
                            <div class="botonAgregar" >
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.codigo}}</div></td>
                                <td><div>{{item.descripcion}}</div></td>
                                <td v-if="rol == 'root'">
                                    <div class="tblOpciones">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                            <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                            <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Eliminar</span>
                                    </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="600px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoading">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Código" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Código" 
                                                    class="pa-0 ma-0" 
                                                    v-model="diccionario.codigo" 
                                                    :error-messages="errors" 
                                                    required
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Descripción" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Descripción" 
                                                    class="pa-0 ma-0" 
                                                    v-model="diccionario.descripcion" 
                                                    :error-messages="errors" 
                                                    required
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                            <v-text-field 
                                                outlined
                                                label="Tabla de referencia" 
                                                class="pa-0 ma-0" 
                                                v-model="diccionario.tabla_referencia"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                            <v-text-field 
                                                outlined
                                                label="Concepto" 
                                                class="pa-0 ma-0" 
                                                v-model="diccionario.concepto" 
                                                :error-messages="errors" 
                                                required
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/Diccionario';
import Notify from '@/plugins/notify';
import apiDiccionario from '@/api/diccionario';



export default {
    components: {
        'data-table': Datatable,
    },
    data() {
        return {
            columns         : [
                {
                    label: 'Código',
                    name: ' codigo',
                    filterable: true
                },
                {
                    label: 'Descripción',
                    name: 'descripcion',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            diccionario     : {
                id                  : null,
                codigo              : null,
                descripcion         : null,
                tabla_referencia    : null,
                concepto            : null

            },
            userData        : this.$session.get('usuario'),
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isSaving        : false,
            imagen          : '',
            datosLogin      : null,
            rol             : null,
        }
    },
    methods: {
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        abrirModal(accion, data = null){
            this.accion     = accion;
            this.isLoading  = true;
            this.imagen     = "/static/icon/catalogos.png"


            if(accion == "add"){
                this.tituloModal = "Registrar código";
                
            } else {
                this.tituloModal = "Actualizar código";
                    this.diccionario.id                 = data.id;
                    this.diccionario.codigo             = data.codigo;
                    this.diccionario.descripcion        = data.descripcion;
                    this.diccionario.tabla_referencia   = data.tabla_referencia;
                    this.diccionario.concepto           = data.concepto;
            }
            this.isLoading  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.diccionario      = {
                id                  : null,
                codigo              : null,
                descripcion         : null,
                tabla_referencia    : null,
                concepto            : null
            }
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
        eliminar(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar la código " + data.codigo + "?",
                "Eliminar",
                () => {
                    apiDiccionario.delete(data.id).then(response => {
                        Notify.Success("Código eliminado", "El código ha sido eliminado satisfactoriamente");
                        this.$apollo.queries.diccionarioDatos.refetch();
                    })
                    .catch(err => {
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                    });
                }
            )
        },
       
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                if(this.accion === "add") {
                    apiDiccionario.store(this.diccionario)
                    .then((response) => {
                        Notify.Success("Excelente!", "El código se guardó satisfactoriamente.");
                        this.$apollo.queries.diccionarioDatos.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                        this.isSaving = false;
                    });
                }
                else if(this.accion === "update") {
                    apiDiccionario.update(this.diccionario)
                    .then((response) => {
                        Notify.Success("Excelente!", "El código se actualizó satisfactoriamente.");
                        this.$apollo.queries.diccionarioDatos.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                        this.isSaving = false;
                    });
                }
            });
        },
    },
    created(){
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        if(this.rol == 'root'){
            this.columns = [
                { label: 'Código', name: ' codigo', filterable: true },
                { label: 'Descripción', name: 'descripcion',filterable: false },
                { label: 'Opciones', name: 'opciones', filterable: false }
            ];
        }else{
            this.columns = [
                { label: 'Código', name: ' codigo', filterable: true },
                { label: 'Descripción', name: 'descripcion',filterable: false }
            ];
        }
        
    },
    mounted(){
        this.$apollo.queries.diccionarioDatos.skip = false;
    },
    apollo: {
        diccionarioDatos: {
            query       : queries.diccionarioTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'codigo',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true;
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.loading    = false;
                this.tableData  = this.diccionarioDatos;
            },
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }

</style>
